import React from 'react';
import './Icon.css';

class Icon extends React.Component {
    render() {
        return (
            <div id="icon-block">
                <img src={this.props.image} alt='calc-icon' />
            </div>
        );
    }
}

export default Icon;