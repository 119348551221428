import React, { useState, useEffect } from 'react';
import Data, { currency, texts, orderNowButton, cutterTypes } from './data';
import Typography from '@material-ui/core/Typography';
import fetch from 'unfetch';


const styles = {
  disclaimer: {
    fontSize: '.6em',
    textAlign: 'center',
    paddingTop: '.6em'
  }
};

const getCurrency = (cur = currency) => {
  if (cur.symbol !== '') {
    return `000 ${String.fromCharCode(cur.symbol)}`;
  }
  return cur.text;
}

const clickCallBack = (calculateFunc) => () => {
  orderNowButton(calculateFunc());
}

export default ({ results, resultCallback, setUsdRate, usdRate }) => {
  const [currentRate, setCurrentRate] = useState(0);

  useEffect(
    () => {
      let cleanupFunc = false;

      const getUsd = async () => {
        if (!cleanupFunc) {
          try {
            const payload = await fetch('https://api.exchangeratesapi.io/latest?base=USD');
            const data = await payload.json();
            const rate = Number(`${String(data.rates.RUB).split('.')[0]}.${String(data.rates.RUB).split('.')[1].slice(0, 2)}`);
            setCurrentRate(rate);
            // to global state
            if (setUsdRate) {
              setUsdRate(rate);
            } 
          } catch (e) {
            console.log(e.message);
          }
        }
      };

      getUsd();
      return () => cleanupFunc = true;
    }
  );

  const total = [...results].reduce((total, item) => total += item.adds, 0);

  if (results.length === 0) {
    return (
      <div id='results-title' className='bg-warning'>
        <h2>{texts.resultHeadline}</h2>
        <p>{texts.resultText}</p>
      </div>
    );
  }

  return (
    <div id='results' className='bg-warning'>
      {
        [...results].map((answerLine, key) => {
          return key !== 3 ? (
            <dl key={answerLine.question}>
              <dt><span className='answer'>{answerLine.question}</span><br />{answerLine.answer}</dt>
              {answerLine.adds !== 0 
                ? <dd>+ {answerLine.adds.toLocaleString('ru-RU')} {getCurrency()}</dd>
                : <dd>---</dd>
              }
            </dl>

          ) : (
              <dl key={answerLine.question}>
                <dt><span className='answer'>{answerLine.question}</span><br />{answerLine.answer}</dt>
                <dd>+ {answerLine.adds.toLocaleString('ru-RU')} {getCurrency()}</dd>
              </dl>
            )
        })
      }
      <div className='total-answer'>
        {results.length < Data.length && <div className='total'>{total.toLocaleString('ru-RU')} {getCurrency()}</div>}
        {results.length === Data.length &&
          <>
            <div
              className='res-right btn btn-success btn-lg w-100 mt-3'
              onClick={clickCallBack(resultCallback)}
            >
              <Typography variant="h5" gutterBottom>
                {total.toLocaleString('ru-RU')} {getCurrency()} *
              </Typography> 
              {texts.orderText}
            </div>
            <span style={styles.disclaimer}>
              * Указана примерная стоимость оборудования. Цена может варьироваться в зависимости от курса USD/RUB (сейчас {currentRate} {String.fromCharCode(currency.symbol)}/USD), а также может измениться в зависимости от особенности комплектации.
              </span>
          </>
        }

      </div>
    </div>
  )
}

