import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

export default ({ names, current, click }) => {
    const clickHandle = (current) => () => {
        if (current < names.length) {
            return click(current);
        };
    };
    return (
        <Stepper
            activeStep={current}
            alternativeLabel style={{ background: 'none' }}
            
        >
            {names.map(
                (label, key) =>
                    (
                        <Step key={label}>
                            <StepLabel style={{ cursor: 'pointer' }} onClick={clickHandle(key)}
                            classes={{
                                completed: 'done'
                            }}
                            >{label}</StepLabel>
                        </Step>
                    )
            )}
        </Stepper>
    );
}

