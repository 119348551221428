import React from 'react';
import Button from './Button';
import Navigation from './Navigation';


const styles = {
    answersblock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    text: {
        color: 'var(--text-color-light)'
    }
}

const getName = (nameField, lastValue) => {
    return typeof nameField === 'function' ? nameField(lastValue) : nameField;
}

export default ({ title, description, answers, clickHandle, steps, questionNum, clickNav, injection, results }) => {
    const lastValue = [...results].pop();
    
    return (
        <div id='question'>
               <Navigation names={[...steps]} current={questionNum} click={clickNav} />
               <h2 style={styles.text}>{title}</h2>
               <p style={styles.text}>{description}</p>
               <div style={styles.answersblock}>
               {
                injection
                    ? injection
                    : answers.map(
                        answer => <Button
                                    key={`${answer.name}${answer.id}`}
                                    name={getName(answer.name, lastValue)}
                                    clickHandle={clickHandle}
                                    value={answer.id}
                                    buttonStyle='btn btn-outline-warning m-2'
                                />
                    )}
               </div>

        </div>
    );
};