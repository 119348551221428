import React from 'react';
import './Calculator.css';
import data, { texts, priceSet, cutterTypes } from './Calculator-Components/data';
import CalculatorQuestion from './Calculator-Components/CalculatorQuestion';
import CalculatorResult from './Calculator-Components/CalculatorResult';
import Results from './Calculator-Components/Results';
import SliderLarge from './Calculator-Components/SliderLarge';
import _ from 'lodash';



export default class Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: [],
            usdRate: null
        };
        this.data = data;

        this.clickHandle = this.clickHandle.bind(this);
        this.toQuestion = this.toQuestion.bind(this);
        this.clearQuiz = this.clearQuiz.bind(this);
        this.setUsdRate = this.setUsdRate.bind(this);
    }

    setUsdRate(currentRate) {
        this.setState({...this.state, usdRate: currentRate});
    }

    clearQuiz() {
        return this.setState({
            answers: []
        });
    }

    changeItem = (arr, index, value) => {
        return [...arr].map(
            (el, key) => {
                if (key === index) {
                    return value;
                }
                return el;
            }
        );
    }

    clickHandle(answerId) {
        const answers = [...this.state.answers, answerId];
        window.localStorage.setItem('userState', answers);
        return this.setState({
            answers: answers,
        });
    }

    setPeople = (e, value) => {
        e.preventDefault();
        const newAnswers = this.changeItem(this.state.answers, 3, value);
        window.localStorage.setItem('userState', [...this.state.answers, value]);
        return this.setState({ answers: [...newAnswers] });
    }


    getSum(quiz, answers) {
        if (answers.length === 0) {
            return 0;
        }
        const result = answers.reduce((acc, answer, index) => {
            const [sum, prev] = acc;
            if (index !== 3) {
                const func = quiz[index].answers[answer].f;
                return [func(sum, prev), answers[index]];
            }

            
            const cutterAdds = Math.ceil(cutterTypes[answers[index]] * this.state.usdRate / 1000);
            
            return [sum + cutterAdds, answers[index]];
        }, [0, null]);

        return result[0];
    }

    buildCheckout(quiz, answers) {
        return answers.reduce((result, answer, answerKey) => {
            const diff = this.getSum(quiz, answers.slice(0, answerKey + 1)) - this.getSum(quiz, answers.slice(0, answerKey));
            
            if (answerKey === 1) { 
                const fanIndex = _.findIndex(priceSet[this.state.answers[0]].answers, item => item.id === this.state.answers[1]);
                return [...result, {
                    question: quiz[answerKey].headline,
                    answer: priceSet[this.state.answers[0]].answers[fanIndex].name,
                    adds: diff
                }];
            };

            if (answerKey !== 3) {
                return [...result, {
                    question: quiz[answerKey].headline,
                    answer: quiz[answerKey].answers[answer].name,
                    adds: diff
                }];
            };

            return [...result, {
                question: texts.personsQty,
                answer: `${answers[3]} ${texts.personsItem}`,
                adds: window.reactcalcData.cutterTypes[answer]
            }];

        }, []);
    }

    toQuestion(index) {
        const newAnswers = [...this.state.answers].slice(0, index);
        return this.setState({
            answers: newAnswers
        });
    }

    getResultsArray() {
        return this.buildCheckout(this.data, this.state.answers);
    }

    makeResultsLine = (resultsArraySrc) => () => {
        const result = [...resultsArraySrc].map(answerLine => {
            return `${answerLine.question}: ${answerLine.answer} (+${answerLine.adds.toLocaleString('ru-RU')})`;
          });
        return result.join(', '); 
    }

    makeSlider = () => {
        return <SliderLarge handleChange={this.setPeople} />;
    }

    render(props) {
        const resultsArray = this.buildCheckout(this.data, this.state.answers);
        if (this.state.answers.length === 3) {
            this.clickHandle(20);
        }
        return this.state.answers.length > this.data.length - 1 
        ? (
            <CalculatorResult
                quiz={this.data}
                clickNav={this.toQuestion}
                checkout={<Results results={resultsArray} resultCallback={this.makeResultsLine(this.getResultsArray())} />}
                total={this.getSum(this.data, this.state.answers)}
                people={this.makeSlider(this.state.answers[3])}
                clearHandle={this.clearQuiz}
            />
        ) : (
            <CalculatorQuestion
                quiz={this.data}
                current={this.state.answers.length}
                clickNav={this.toQuestion}
                results={this.state.answers}
                clickHandle={this.state.answers.length !== 3 ? this.clickHandle : this.setPeople}
                injection={
                    this.state.answers.length === 3 ? this.makeSlider(this.state.answers[2]) : false
                }
                checkout={<Results results={resultsArray} clearHandle={this.clearQuiz} usdRate={this.state.usdRate} setUsdRate={this.setUsdRate} />}
            />
            
            
        );
    }
};