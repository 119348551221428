import img1 from '../img/img-1.jpg';
import img2 from '../img/img-2.jpg';
import img3 from '../img/img-3.jpg';
import img4 from '../img/img-4.jpg';

import icon1 from '../img/icon-1.svg';
import icon2 from '../img/icon-2.svg';
import icon3 from '../img/icon-3.svg';
import icon4 from '../img/icon-4.svg';

export const costPerEmployee = window.reactcalcData ? window.reactcalcData.costPerEmployee : 100;
export const currency = window.reactcalcData.currency ? window.reactcalcData.currency : 'Руб';
export const texts = window.reactcalcData.texts;
export const orderNowButton = window.reactcalcData.orderNowButton;
export const priceSet = window.reactcalcData.userPriceSet;
export const cutterTypes = window.reactcalcData.cutterTypes;

export default [
    { ...window.reactcalcData.questions[0], image: img1, icon: icon1 },
    { ...window.reactcalcData.questions[1], image: img2, icon: icon2 },
    { ...window.reactcalcData.questions[2], image: img3, icon: icon3 },
    { ...window.reactcalcData.questions[3], image: img4, icon: icon4 },
];