import React from 'react';

class Button extends React.Component {
    constructor(props) {
        super(props);
        
        this.clickHandle = this.clickHandle.bind(this);
    }

    clickHandle() {
        this.props.clickHandle(this.props.value, this.props.f); 
    }

    render(props) {
        const { buttonStyle, name } = this.props;
        return (
            <div className={buttonStyle} onClick={this.clickHandle}>{name}</div>
        );
    }
 }

 export default Button;