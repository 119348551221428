import React from 'react';
import './Image.css';

export default ({ image }) => {
    const styles = {
        wrapper: {
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            overflow: 'hidden',
        }
    };
    return (
        <div id="img-block" style={styles.wrapper} />
    );
}
