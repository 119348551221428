import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { cutterTypes } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '0 40px',
  },
  margin: {
    height: theme.spacing(3),
  },
}));

export default function DiscreteSlider({ handleChange }) {
  const classes = useStyles();

  const marks = Object.keys(cutterTypes).reduce((result, mark) => {
    return [...result, {value: mark, label: mark}];
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.margin} />
      <Slider
        classes={{
          markLabelActive: 'se-active',
          rail: 'rail',
          mark: 'mark'
        }}
        defaultValue={20}
        aria-labelledby="discrete-slider-always"
        step={null}
        min={5}
        max={70}
        onChange={handleChange}
        marks={marks}
        valueLabelDisplay="on"
      />
    </div>
  );
}

