import React from 'react';
import Image from './Image';
import Icon from './Icon';
import Question from './Question';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';



export default ({ quiz, current, clickNav, clickHandle, checkout, injection, results }) => {
    const { image, icon, description, headline, answers } = quiz[current];
    const namesArray = quiz.map(question => question.headline);
    const stepsArray = quiz.map(question => question.step);

    return (
        <Fade timeout={1000} in={true}>
            <Grid container spacing={0} direction="row" alignItems="stretch">
                <Hidden mdDown>
                    <Grid item xl={2} lg={2}><Icon image={icon} /></Grid>
                </Hidden>
                <Hidden mdDown>
                    <Grid item xl={2} lg={2}><Image image={image} /></Grid>
                </Hidden>
                <Grid item xl={5} lg={5} md={8} sm={7} xs={12}>
                    <Question
                        names={namesArray}
                        steps={stepsArray}
                        questionNum={current}
                        clickNav={clickNav}
                        results={results}
                        description={description}
                        title={headline}
                        answers={answers}
                        clickHandle={clickHandle}
                        injection={injection}
                    />
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={5} xs={12}>{checkout}</Grid>
            </Grid>
        </Fade>
    );
}
